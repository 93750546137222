import React from 'react';
import ScheduleDisplay from '../components/ScheduleDisplay';
import PriceScreen from '../components/PriceScreen';
import PriceScreenCenter from '../components/PriceScreenCenter';
import Elgin160Data from '../content/elgin-160.json';
import AutoRefresh from '../components/AutoRefreshProps';

function Edifice511() {

  const rates = [
    {
      description: 'Daily / Monday to Friday (6:00 am - 6:00 pm)',
      descriptionFr: 'Lundi au vendredi / 6h à 18h',
      price: '$21',
    },
    {
      description: 'Nightly / Monday to Friday (6:00 pm - 6:00 am)',
      descriptionFr: 'Lundi au vendredi / 18h à 6h',
      price: '$10',
    },
    {
      description: 'Friday 6:00 pm - Monday 6:00 am',
      descriptionFr: 'Vendredi 18h à Lundi 6h',
      price: '$10',
    },
  ];

  const dayContent = (
    <PriceScreen
      title={Elgin160Data.day.title}
      lines={Elgin160Data.day.lines}
      thankYouText={Elgin160Data.thankYou}
    />
  );

  const dayContent2 = (
  <div
    className="flex h-screen flex-col justify-between"
    style={{ backgroundColor: '#23339D' }}
  >
    <header
      className="relative border-b-2 p-4 min-h-[8vh]"
      style={{ backgroundColor: '#fff', borderColor: '#000' }}
    >
      <div className="items-center h-full flex justify-center">
        <img
          src={"https://2csmpgl1jb.ufs.sh/f/h80Ega1oO5qNsGqryG0eofsyb47ZhMrC1dOu36lQtHRKcxvq"}
          alt="Parkeo Logo"
          className="max-h-[9.6vh] min-w-[30vw] max-w-[50%] object-contain pb-8 pt-8"
        />
      </div>
      <div className="absolute left-[calc(-300px+-1.6vh)] top-[calc(-220px-5.6vh)] h-[calc(400px+12.6vh)] w-[calc(400px+9.6vh)] -translate-x-8 rotate-45 transform bg-secondary"></div>
    </header>

    <div
      className="flex flex-grow flex-col justify-between px-[8vw] py-[2vh]"
      style={{ backgroundColor: '#121B4D', color: '#fff' }}
    >
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2 className="mb-[-1vh] text-[10vw] font-bold">
            RATES
          </h2>
          <p
            className="text text-[6vw] font-bold leading-none"
            style={{ color: '#8FBA02' }}
          >
            Tarifs
          </p>
        </div>
      </div>

      <div className="mt-[-10vh] flex justify-end">
        <h1 className="text-[17vh] font-bold mr-[6vw]">
          15
        </h1>
        <span className=" ml-[-70px] text-[10vh] font-bold leading-[2] mr-[10px]">
              𝇍
            </span>
        <div className="flex flex-col items-center justify-end pb-[10%]">

          <p className="text-right text-[6vw] mb-[5vh]">/ MIN</p>
          <p className="text-right text-[4vw] font-bold">
            Max 21$
          </p>
        </div>
      </div>

      <div className="border-t-[8px]" style={{ borderColor: '#8FBA02' }}>
        {rates.map((rate, index) => (
          <div
            key={index}
            className="flex justify-between border-b-[8px] py-[1.7vh]"
            style={{ borderColor: '#8FBA02' }}
          >
            <div className="flex max-w-[69%] flex-col justify-center">
              <p className="text-[4vw] font-bold leading-[1.2]">
                {rate.description}
              </p>
              <p
                className="text-[1.6vh] font-bold leading-[1.2]"
                style={{ borderColor: '#8FBA02' }}
              >
                {rate.descriptionFr}
              </p>
            </div>

            <p
              className="flex flex-col justify-center text-[5vw] font-extrabold"
              style={{ color: '#8FBA02' }}
            >
              {rate.price}
            </p>
          </div>
        ))}
        <p className="mt-[2vh] text-[1.2vh] font-bold">
          Rates based on 12 hours time limit, plus service fees if applicable.
          <br /> Tarifs Basés pour une limite de 12 heures, plus frais de
          service, le cas échéant
        </p>
      </div>
    </div>

    <footer
      className="flex items-center justify-between px-[4vw] py-8"
      style={{ color: '#fff', backgroundColor: '#8FBA02' }}
    >
      <div className="flex flex-col justify-center">
        <p className="text-[6vw] font-extrabold" style={{ color: '#121B4D' }}>
          418 440-4638
        </p>
        <p className="text-[4vw] font-bold" style={{ color: '#121B4D' }}>
          www.parkeo.ca
        </p>
      </div>
      <img src={"https://swqvc4jret.ufs.sh/f/aGnUnqmi8ZcvISLsdMy90v6KjHCBGPeFM7ZVlwaISyA21ir8"} alt="Logo Parkeo" className="h-[6vh]" />
    </footer>
  </div>
  )

  const nightContent = (
    <PriceScreen
      title={Elgin160Data.night.title}
      lines={Elgin160Data.night.lines}
      thankYouText={Elgin160Data.thankYou}
    />
  );

  const weekendContent = (
    <PriceScreen
      title={Elgin160Data.weekend.title}
      lines={Elgin160Data.weekend.lines}
      thankYouText={Elgin160Data.thankYou}
    />
  );

  return (
    <div>
      <AutoRefresh intervalms={900000} /> {/* 15 min */}
      <ScheduleDisplay
        dayContent={dayContent2}
        nightContent={dayContent2}
        weekendContent={dayContent2}
        dayStartTime="06:00:00"
        nightStartTime="18:00:00"
        weekStartTime="06:00:00"  // La semaine commence à 6h le lundi
        weekEndTime="18:00:00"    // La semaine se termine à 18h le dimanche
      />
      {/* <RotatingComponents screens={screens} interval={3000} /> */}
    </div>
  );
}

export default Edifice511;
