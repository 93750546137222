import React from 'react';

function Indigo41George() {
  const rates = [
    { description: '24 h', price: '$24.00' },
    {
      description: 'Daily / Monday to Friday (6:00 am - 6:00 pm)',
      descriptionFr: 'Lundi au vendredi / 6h à 18h',
      price: '$19.50',
    },
    {
      description: 'Weekend / Saturday - Sunday',
      descriptionFr: 'Samedi et dimanche',
      price: '$19.50',
    },
    {
      description: 'Evening (6:00 pm - 6:00 am)',
      descriptionFr: 'Soirée / 18 h à 6 h',
      price: '$19.50',
    },
    {
      description: 'Lost ticket',
      descriptionFr: 'Billet perdu',
      price: '$60.00',
    },
  ];

  return (
    <div
      className="flex h-screen flex-col justify-between"
      style={{ backgroundColor: '#23339D' }}
    >
      <header
        className="relative border-b-2 p-4"
        style={{ backgroundColor: '#fff', borderColor: '#000' }}
      >
        <div className="items-right flex justify-end">
          <img
            src={"https://swqvc4jret.ufs.sh/f/aGnUnqmi8ZcvsNnth6gfVxRnUjehuSBqQJ6E2ymNLocgOA9a"}
            alt="JPark Logo"
            className="max-h-[9.6vh] min-w-[30vw] object-contain"
          />
        </div>
        <div className="absolute left-[calc(-300px+-1.6vh)] top-[calc(-220px-5.6vh)] h-[calc(400px+12.6vh)] w-[calc(400px+9.6vh)] -translate-x-8 rotate-45 transform bg-secondary"></div>
      </header>

      <div
        className="flex flex-grow flex-col justify-between px-[8vw] py-[2vh]"
        style={{ backgroundColor: '#23339D', color: '#fff' }}
      >
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h2 className="mb-[-1vh] text-[10vw] font-bold">
              RATES
            </h2>
            <p
              className="text text-[6vw] font-bold leading-none"
              style={{ color: '#8FBA02' }}
            >
              Tarifs
            </p>
          </div>
        </div>

        <div className="mt-[-10vh] flex justify-end">
          <h1 className="text-[17vh] font-bold mr-[6vw]">
            15
          </h1>
          <span className=" ml-[-70px] text-[10vh] font-bold leading-[2] mr-[10px]">
              𝇍
            </span>
          <div className="flex flex-col items-center justify-end pb-[10%]">

            <p className="text-right text-[6vw] mb-[5vh]">/ MIN</p>
            <p className="text-right text-[4vw] font-bold">
              Max 21$
            </p>
          </div>
        </div>

        <div className="border-t-[8px]" style={{ borderColor: '#F6D21D' }}>
          {rates.map((rate, index) => (
            <div
              key={index}
              className="flex justify-between border-b-[8px] py-[1.7vh]"
              style={{ borderColor: '#F6D21D' }}
            >
              <div className="flex max-w-[69%] flex-col justify-center">
                <p className="text-[4vw] font-bold leading-[1.2]">
                  {rate.description}
                </p>
                <p
                  className="text-[1.6vh] font-bold leading-[1.2]"
                  style={{ borderColor: '#8FBA02' }}
                >
                  {rate.descriptionFr}
                </p>
              </div>

              <p
                className="flex flex-col justify-center text-[5vw] font-extrabold"
                style={{ color: '#F6D21D' }}
              >
                {rate.price}
              </p>
            </div>
          ))}
          <p className="mt-[2vh] text-[1.2vh] font-bold">
            Rates based on 12 hours time limit, plus service fees if applicable.
            <br /> Tarifs Basés pour une limite de 12 heures, plus frais de
            service, le cas échéant
          </p>
        </div>
      </div>

      <footer
        className="py-6 text-center"
        style={{ color: '#fff', backgroundColor: '#8FBA02' }}
      >
        <p
          className="text-[2vh] font-extrabold"
          style={{ color: '#292520' }}
        >
          www.j-park.ca
        </p>
        <p
          className="text-[6vw] font-extrabold"
          style={{ color: '#292520' }}
        >
          (343) 204-1468
        </p>
      </footer>
    </div>
  );
}

export default Indigo41George;
