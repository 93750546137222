import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import AnimatedCircles from '../components/AnimatedCircles';

function Menu() {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-between border-[1vh] border-secondary bg-primary p-[3vw] font-custom text-white">
      <Header title="Menu" logo={"https://swqvc4jret.ufs.sh/f/aGnUnqmi8Zcvpa0fujKwgtP8o6YbXV7uO35re2HMjxEdlDfT"} />
      <div className="flex min-h-[60svh] justify-center gap-5 items-center">
        <Link to="/jardin-st-roch">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            Jardin St Roch
          </button>
        </Link>
        <Link to="/edifice-511">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            Edifice 511
          </button>
        </Link>
        <Link to="/delta-hotel">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            Delta Hotel
          </button>
        </Link>
        <Link to="/indigo-41-george">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            Indigo 41 George
          </button>
        </Link>
        <Link to="/160-elgin">
          <button
            type="button"
            className="w-full rounded-lg border-[10px] border-secondary bg-secondary px-8 py-4 text-[2vw] font-bold text-white transition duration-300 hover:border-white"
          >
            160 Elgin
          </button>
        </Link>
      </div>
      <p className="mb-4 self-start text-left text-[2vw] font-semibold text-white">
        Merci pour votre visite !!
      </p>
      <div className="absolute bottom-8 right-8">
        <AnimatedCircles />
      </div>
    </div>
  );
}

export default Menu;
