import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';

interface ScheduleDisplayProps {
  dayContent: React.ReactNode;
  nightContent: React.ReactNode;
  weekendContent?: React.ReactNode;
  dayStartTime: string; // Format 'HH:mm:ss'
  nightStartTime: string; // Format 'HH:mm:ss'
  weekStartTime?: string;
  weekEndTime?: string;
}

function ScheduleDisplay({
                           dayContent,
                           nightContent,
                           weekendContent,
                           dayStartTime,
                           nightStartTime,
                           weekStartTime,
                           weekEndTime,
                         }: ScheduleDisplayProps) {
  const [mode, setMode] = useState<'day' | 'night' | 'weekend'>('day');

  const checkTime = useCallback((): NodeJS.Timeout => {
    const timeNow = moment().tz('America/Montreal');
    console.log('Heure actuelle :', timeNow.format('YYYY-MM-DD HH:mm:ss'));

    const dayTime = moment(
      `${timeNow.format('YYYY-MM-DD')}T${dayStartTime}`
    ).tz('America/Montreal');
    const nightTime = moment(
      `${timeNow.format('YYYY-MM-DD')}T${nightStartTime}`
    ).tz('America/Montreal');

    console.log('Heure de début du jour :', dayTime.format('YYYY-MM-DD HH:mm:ss'));
    console.log('Heure de début de la nuit :', nightTime.format('YYYY-MM-DD HH:mm:ss'));

    let newMode: 'day' | 'night' | 'weekend' = timeNow.isBefore(nightTime) ? 'day' : 'night';
    console.log('Mode initial (jour/nuit) :', newMode);

    if (weekendContent && weekStartTime && weekEndTime) {
      const weekStart = moment().tz('America/Montreal').isoWeekday(1);
      const [wsHour, wsMinute, wsSecond] = weekStartTime.split(':').map(Number);
      weekStart.set({ hour: wsHour, minute: wsMinute, second: wsSecond });
      console.log('Début de la semaine (lundi) :', weekStart.format('YYYY-MM-DD HH:mm:ss'));

      const weekEnd = moment().tz('America/Montreal').isoWeekday(7);
      const [weHour, weMinute, weSecond] = weekEndTime.split(':').map(Number);
      weekEnd.set({ hour: weHour, minute: weMinute, second: weSecond });
      console.log('Fin de la semaine (dimanche) :', weekEnd.format('YYYY-MM-DD HH:mm:ss'));

      // Si l'heure actuelle est hors de l'intervalle de la semaine, passer en mode weekend
      if (timeNow.isBefore(weekStart) || timeNow.isAfter(weekEnd)) {
        newMode = 'weekend';
        console.log("L'heure actuelle est hors de l'intervalle de la semaine, mode => weekend");
      } else {
        console.log("L'heure actuelle est dans l'intervalle de la semaine");
      }
    }

    setMode(newMode);
    console.log('Mode mis à jour :', newMode);

    // Calcul du prochain temps de changement :
    const nextTransitions: moment.Moment[] = [];

    // Transition jour/nuit
    if (newMode === 'day') {
      nextTransitions.push(nightTime);
      console.log('Prochaine transition (jour->nuit) à :', nightTime.format('YYYY-MM-DD HH:mm:ss'));
    } else if (newMode === 'night') {
      const nextDayTime = dayTime.clone().add(1, 'days');
      nextTransitions.push(nextDayTime);
      console.log('Prochaine transition (nuit->jour) à :', nextDayTime.format('YYYY-MM-DD HH:mm:ss'));
    }

    // Transitions liées au mode week-end
    if (weekendContent && weekStartTime && weekEndTime) {
      const weekStart = moment().tz('America/Montreal').isoWeekday(1);
      const [wsHour, wsMinute, wsSecond] = weekStartTime.split(':').map(Number);
      weekStart.set({ hour: wsHour, minute: wsMinute, second: wsSecond });
      const weekEnd = moment().tz('America/Montreal').isoWeekday(7);
      const [weHour, weMinute, weSecond] = weekEndTime.split(':').map(Number);
      weekEnd.set({ hour: weHour, minute: weMinute, second: weSecond });

      if (timeNow.isAfter(weekEnd)) {
        const nextWeekStart = weekStart.clone().add(1, 'weeks');
        nextTransitions.push(nextWeekStart);
        console.log('Prochaine transition (weekend->semaine) à :', nextWeekStart.format('YYYY-MM-DD HH:mm:ss'));
      } else if (timeNow.isBefore(weekStart)) {
        nextTransitions.push(weekStart);
        console.log('Prochaine transition (avant semaine, début semaine) à :', weekStart.format('YYYY-MM-DD HH:mm:ss'));
      } else if (timeNow.isBetween(weekStart, weekEnd)) {
        nextTransitions.push(weekEnd);
        console.log('Prochaine transition (dans semaine, fin semaine) à :', weekEnd.format('YYYY-MM-DD HH:mm:ss'));
      }
    }

    const validTransitions = nextTransitions.filter(t => t.isAfter(timeNow));
    const nextChangeTime = moment.min(...validTransitions);
    console.log('Prochaine transition effective :', nextChangeTime.format('YYYY-MM-DD HH:mm:ss'));

    const timeoutDuration = nextChangeTime
      ? nextChangeTime.diff(timeNow)
      : 60000;
    console.log('Durée du timeout (ms) :', timeoutDuration);

    return setTimeout(checkTime, timeoutDuration);
  }, [dayStartTime, nightStartTime, weekStartTime, weekEndTime, weekendContent]);

  useEffect(() => {
    const timer = checkTime();
    return () => clearTimeout(timer);
  }, [checkTime]);

  return (
    <div className="h-screen w-full">
      {mode === 'weekend' && weekendContent
        ? weekendContent
        : mode === 'day'
          ? dayContent
          : nightContent}
    </div>
  );
}

export default ScheduleDisplay;
